import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Platform from '../constants/Platform';
import { Divider, Drawer, Hidden, Collapse, Button, TextareaAutosize } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import PieChartIcon from "@material-ui/icons/PieChart";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PeopleIcon from "@material-ui/icons/People";
import LockIcon from "@material-ui/icons/Lock";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import firebase from 'firebase/app'
import Colors from '../constants/Colors';
import HomePage from '../page/HomePage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SessionHelper from '../helper/SessionHelper';
import UserPage from "../page/UserPage";
import PermissionPage from "../page/PermissionPage";
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import NotificationCenter from "../components/NotificationCenter";
import moment from "moment";
import MapsRequestedPage from "../page/MapsRequestedPage";

const drawerWidth = 240;

export default class navigation extends React.Component {

    logout() { firebase.auth().signOut(); window.location.href = '/' }

    state = {
        actualRoute: null,
        settingsMenuOpen: false,
        storeMenuOpen: false,
        socialMenuOpen: false,
        aboutModal: false,
        helpModal: false,
        subject: '',
        message: '',
        plan: SessionHelper.getData().plan || null,
    }

    componentDidMount() {

        this.setState({ actualRoute: window.location.pathname });
    }

    handleRouteChange() {
        setTimeout(() => {
            this.setState({ actualRoute: window.location.pathname });
        }, 10)
    }

    handleClick(menu) {
        let state = this.state;
        state[menu] = !state[menu];

        this.setState(state);
    }

    aboutModal() {
        if (this.state.aboutModal) {
            return (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <img style={{ height: 65, marginLeft: 'auto', marginRight: 'auto', marginBottom: 8 }} src={process.env.PUBLIC_URL + '/logo.png'} />
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 10 }}>
                            {`Ferramenta Admin AdvisMap`}
                        </div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey', marginTop: 25 }}>admin.advismap.grupoadvis.com.br | Versão {Platform.version}</div>
                        <div style={{ textAlign: 'center', fontSize: 11, color: 'grey' }}>Copyright Advis © {moment().format('YYYY')} Todos os direitos reservados</div>
                    </div>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.setState({ aboutModal: false }) }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'FECHAR'}</Button>
                    </div>
                </div>
            )
        }
    }

    async sendMessage() {

        //TODO;
    }

    helpModal() {
        if (this.state.helpModal) {
            return (
                <div>
                    <div style={{ paddingBottom: 20 }}>{'Envie a sua mensagem e em breve entraremos em contato para solucionar o seu problema.'}</div>
                    <DefaultInput onChange={(text) => { this.setState({ subject: text }) }} label={'Assunto'} />
                    <TextareaAutosize style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginTop: 20 }} rowsMax={8} rowsMin={8} onChange={(v) => { this.setState({ message: v.target.value }) }} placeholder="Escreva sua mensagem..." />
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <Button onClick={() => { this.sendMessage() }} style={{ fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '100%' }} variant={'contained'}>{'ENVIAR MENSAGEM'}</Button>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" style={{ width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth, backgroundColor: Colors.primary }}>
                    <Toolbar>
                        <Typography style={{ fontWeight: 'bold' }} variant="h6" noWrap>
                            Painel Administrador AdvisMap
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <NotificationCenter />
                    </Toolbar>
                </AppBar>
                <BrowserRouter forceRefresh={false}>
                    <nav style={{ width: drawerWidth, flexShrink: 0, }} aria-label="mailbox folders">
                        <Hidden xsDown implementation="css">
                            <Drawer
                                PaperProps={{ style: { width: drawerWidth } }}
                                variant="permanent"
                                open>
                                <div>
                                    <div />
                                    <Divider />
                                    <List>
                                        <ListItem component={Link} button to={'/'}>
                                            {!SessionHelper.getData().company.logo ? <img style={{ height: 38, marginBottom: 8 }} src={process.env.PUBLIC_URL + '/logo.png'} /> : <img style={{ height: 38, marginBottom: 8 }} src={SessionHelper.getData().company.logo} />}
                                        </ListItem>
                                        <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/' && true} style={this.state.actualRoute === '/' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/'}>
                                            <ListItemIcon><PieChartIcon style={this.state.actualRoute === '/' ? { color: '#fff' } : {}} /></ListItemIcon>
                                            <ListItemText style={this.state.actualRoute === '/' ? { color: '#fff' } : {}} primary={'Dashboard'} />
                                        </ListItem>

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.maps_requested ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/maps_requested' && true} style={this.state.actualRoute === '/maps_requested' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/maps_requested'}>
                                                <ListItemIcon><WebAssetIcon style={this.state.actualRoute === '/maps_requested' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/maps_requested' ? { color: '#fff' } : {}} primary={'Análise Site'} />
                                            </ListItem>

                                            : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.user ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/user' && true} style={this.state.actualRoute === '/user' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/user'}>
                                                <ListItemIcon><PeopleIcon style={this.state.actualRoute === '/user' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/user' ? { color: '#fff' } : {}} primary={'Usuários'} />
                                            </ListItem>

                                            : null}

                                        {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.permission ?

                                            <ListItem onClick={() => { this.handleRouteChange() }} selected={this.state.actualRoute === '/permission' && true} style={this.state.actualRoute === '/permission' ? { backgroundColor: Colors.primary } : {}} component={Link} button to={'/permission'}>
                                                <ListItemIcon><LockIcon style={this.state.actualRoute === '/permission' ? { color: '#fff' } : {}} /></ListItemIcon>
                                                <ListItemText style={this.state.actualRoute === '/permission' ? { color: '#fff' } : {}} primary={'Permissões'} />
                                            </ListItem>

                                            : null}

                                        <ListItem selected={this.state.settingsMenuOpen ? true : false} button onClick={() => { this.handleClick('settingsMenuOpen') }}>
                                            <ListItemIcon>
                                                <SettingsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Configurações" />
                                            {this.state.settingsMenuOpen ? <ExpandMore /> : <ChevronRight />}
                                        </ListItem>
                                        <Collapse in={this.state.settingsMenuOpen} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>

                                                <ListItem component={Link} button onClick={() => { this.setState({ aboutModal: true }) }}>
                                                    <ListItemIcon><InfoIcon /></ListItemIcon>
                                                    <ListItemText primary={'Sobre'} />
                                                </ListItem>

                                            </List>
                                        </Collapse>

                                        <ListItem component={Link} button onClick={() => { this.setState({ helpModal: true }) }}>
                                            <ListItemIcon><HeadsetMicIcon /></ListItemIcon>
                                            <ListItemText primary={'Suporte'} />
                                        </ListItem>

                                        <ListItem component={Link} button onClick={() => { this.logout() }} to={'/'}>
                                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                            <ListItemText primary={'Sair'} />
                                        </ListItem>
                                    </List>
                                </div>
                            </Drawer>
                        </Hidden>
                    </nav>

                    <main style={{ flexGrow: 1, paddingTop: 60 }}>
                        <Switch>
                            <Route exact path="/" render={() => <HomePage />} />
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.user ? <Route path="/user" render={() => <UserPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.permission ? <Route path="/permission" render={() => <PermissionPage />} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.params ? <Route path="/params" render={() => <div></div>} /> : null}
                            {SessionHelper.getData().permission && SessionHelper.getData().permission.routes.maps_requested ? <Route path="/maps_requested" render={() => <MapsRequestedPage />} /> : null}
                        </Switch>
                    </main>
                </BrowserRouter>
                <ToastContainer style={{ paddingTop: 100 }} />
                <DefaultModal loading={this.state.loadingModal} content={this.aboutModal()} title={'Sobre'} onClose={() => { this.setState({ aboutModal: false }) }} open={this.state.aboutModal} />
                <DefaultModal loading={this.state.loadingModal} content={this.helpModal()} title={'Suporte'} onClose={() => { this.setState({ helpModal: false }) }} open={this.state.helpModal} />
            </div>
        )
    }
}
