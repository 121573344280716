import React from "react";
import FirebaseConfig from './constants/Firebase';
import firebase from 'firebase/app';
import 'firebase/auth'
import LoginNavigator from './navigation/LoginNavigator';
import RootNavigator from './navigation/RootNavigator';
import DefaultLoader from './components/DefaultLoader';
import SessionHelper from './helper/SessionHelper';
import Firestore from './api/firebase/Firestore';

firebase.initializeApp(FirebaseConfig);

function authUser() {
    return new Promise(function (resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {

            if (user) {
                SessionHelper.setFirebaseAuth(user);

                let userDoc = await Firestore.getDoc('user', user.uid);
                let data;

                if (userDoc.exists) {

                    data = userDoc.data();

                    if (userDoc.data().id_company) {

                        let company = await Firestore.getDoc('company', userDoc.data().id_company);

                        if (company.exists) {

                            data.company = company.data();
                        }
                    }

                    if (userDoc.data().id_permission_group) {

                        let permission = await Firestore.getDoc('permission_group', userDoc.data().id_permission_group);

                        if (permission.exists) {

                            data.permission = permission.data();
                        }
                    }

                    SessionHelper.setData(data)
                }

            } else {

                SessionHelper.destroy();
            }

            resolve(user);
        });
    });
}

export default class App extends React.Component {

    state = {
        isAuthenticating: true,
        user: null,
    }

    componentDidMount() {

        authUser().then((user) => {
            this.setState({ isAuthenticating: false, user: user });
        }, (error) => {
            this.setState({ isAuthenticating: false });
            alert(error);
        });
    }

    render() {

        if (this.state.isAuthenticating) return <DefaultLoader />;

        return this.state.user ? <RootNavigator /> : <LoginNavigator />;
    }
}