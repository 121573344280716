import React, { Component } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import Colors from '../constants/Colors';

export default class DefaultLoader extends Component {
  render() {
    return (
      <ClipLoader
        css={css`${this.props.css ? this.props.css : `display: flex;margin: 0 auto;margin-top:22%;justify-content:center;align-items:center`}`}
        size={this.props.size ? this.props.size : 50}
        color={this.props.color ? this.props.color : Colors.primary}
        loading={true}
      />
    );
  }
}
