import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class components extends Component {
    
  render() {

    return <TextField defaultValue={this.props.defaultValue} multiline={this.props.multiline ? this.props.multiline : false}  disabled={this.props.disabled ? this.props.disabled : false} helperText={this.props.helperText} error={this.props.error} value={this.props.value} onBlur={(evt) => { this.props.onBlur && this.props.onBlur(evt.target.value) }} onChange={(evt) => { this.props.onChange && this.props.onChange(evt.target.value) }} required={this.props.required} fullWidth={true} type={this.props.type} style={ this.props.style ? this.props.style : { paddingTop: 10, paddingBottom: 10, color: Colors.primary, borderBottomColor: Colors.primary }} id={this.props.id} label={this.props.label} />

  }
}
