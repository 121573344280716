import React, { Component } from 'react';
import DefaultLoader from './DefaultLoader';
import DefaultButton from './DefaultButton';
import { Card, IconButton, Tooltip, Grid, TextareaAutosize } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import { Radar, Line, Bar } from 'react-chartjs-2';
import brLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import SessionHelper from '../helper/SessionHelper';
import { toast } from 'react-toastify';
import Colors from '../constants/Colors';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

const gridSize = window.screen.width < 1080 ? 12 : 6;

export default class AdvisMapResult extends Component {

    state = {
        loading: true,
        answers: {},
        data: {}
    }

    async componentDidMount() {
        await this.getData();
        this.setGraphData();

        this.setState({ loading: false });
    }

    async getData() {
        let doc = await Firestore.getDoc('contact', this.props.editId);

        if(doc.exists) {
            let { answers, comment, company, name } = doc.data();

            this.setState({ answers, comment, company, name  });
        }
    }

    getLookUp() {
        return {
            evf: 'Estratégia e Visão de Futuro',
            cco: 'Cultura e Clima Organizacional',
            mdg: 'Modelo de Gestão',
            lt: 'Liderança Transformadora',
            gedp: 'Gestão Estratégica de Pessoas'
        }
    }

    getOptionLookUp() {
        return {
            1: { label: 'Não Pratica', color: 'rgb(184, 9, 0)' },
            2: { label: 'Inicial', color: 'rgb(203, 122, 0)' },
            3: { label: 'Em Desenvolvimento', color: 'rgb(219, 193, 27)' },
            4: { label: 'Em Evolução', color: 'rgb(0, 102, 186)' },
            5: { label: 'Consolidado', color: 'rgb(2, 163, 50)' }
        }
    }

    getQuestionLookUp() {
        return {
            'evf-1': 'Os norteadores estratégicos (missão, visão e valores) estão definidos e são disseminados em toda a Organização?',
            'evf-2': 'Existe uma visão de futuro com metas plurianuais?',
            'evf-3': 'Existe um plano estratégico com objetivos e metas definidos?',
            'evf-4': 'Existem projetos de transformação elaborados e executados por comitês de gestão?',
            'cco-1': 'Existe uma cultura voltada para a superação das metas de resultado?',
            'cco-2': 'Percebe-se um ambiente de colaboração mútua entre pessoas e departamentos para o alcance dos objetivos traçados?',
            'cco-3': 'A Organização possui um Código de Ética e Conduta disseminado, com um Comitê de Conduta atuante e um Canal de Denúncias ativo?',
            'cco-4': 'Ocorre avaliação periódica de cultura e clima, com ações corretivas e de melhoria, na busca de evolução contínua?',
            'mdg-1': 'Os indicadores estratégicos estão definidos e são gerenciados sistematicamente pelos responsáveis por essas métricas?',
            'mdg-2': 'O orçamento é planejado anualmente, com revisões no mínimo semestrais, e gerenciado sistematicamente pelos responsáveis por receitas, custos e despesas?',
            'mdg-3': 'Existem reuniões sistemáticas para apresentação de análise crítica de resultados e planos de ação?',
            'mdg-4': 'São elaborados e executados planos de ação para resolver os problemas de resultados e de processos, com verificação da sua eficácia?',
            'lt-1': 'Os gestores acompanham os resultados e promovem uma análise profunda sobre os resultados abaixo das metas?',
            'lt-2': 'Os gestores conhecem os processos chave e de gestão, e atuam efetivamente para a melhoria dos mesmos?',
            'lt-3': 'Os gestores praticam os comportamentos desejados para consolidar a cultura, com atitudes que servem de exemplo para suas equipes?',
            'lt-4': 'Os gestores tem o hábito de parar periodicamente para avaliar suas equipes, dar feedback e desenvolvê-las?',
            'gedp-1': 'Existe uma prática sistemática de avaliação e gestão de desempenho, contemplando performance e competências, com feedback e plano de desenvolvimento períódico?',
            'gedp-2': 'Existe uma sistemática para identificar as necessidades de desenvolvimento, individuais e coletivas?',
            'gedp-3': 'Existem politicas internas que promovam o crescimento de carreira e a sucessão?',
            'gedp-4': 'Existem programas de meritocracia atrelados a desempenho individual e coletivo, para gestores e equipes, alinhados com os resultados da Organização?',
        }
    }

    setGraphData() {
        let data = {
            labels: [],
            datasets: [{
                label: 'Pontuação',
                data: [],
                borderWidth: 3,
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
            }]
        };

        Object.keys(this.getLookUp()).forEach((key) => {
            let mean = 0;
            let count = 0;

            data.labels.push(this.getLookUp()[key]);

            for (let answerKey in this.state.answers[key]) {
                mean += parseInt(this.state.answers[key][answerKey]);
                count++;
            }

            mean = mean / count;

            data.datasets[0].data.push(mean);
        });

        this.setState({ data });
    }

    saveComment = async () => {
        try {
            if (this.props.editId) {
                await Firestore.update({ comment: this.state.comment || '' }, 'contact', this.props.editId);
                toast.success("Parecer salvo com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                throw new Error('No edit id');
            }
        } catch (e) {
            console.log(e);
            toast.error("Erro ao salvar parecer", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    render() {

        return this.state.loading ? <DefaultLoader /> : (
            <div style={{ width: '100%' }}>
                <Radar
                    data={this.state.data}
                    options={{ maintainAspectRatio: true, scale: { ticks: { min: 1, max: 5 } } }}
                />

                {
                    Object.keys(this.getLookUp()).map(key => (
                        <div style={{ marginTop: 15, borderTop: '1px solid grey' }}>
                            <h3>{this.getLookUp()[key]}</h3>
                            <div>{Object.keys(this.state.answers[key]).map(quesionKey => (
                                <div>
                                    <p>{this.getQuestionLookUp()[quesionKey]}</p>
                                    <b style={{ color: this.getOptionLookUp()[this.state.answers[key][quesionKey]].color }}>{this.getOptionLookUp()[this.state.answers[key][quesionKey]].label}</b>
                                </div>
                            ))}</div>
                        </div>
                    ))
                }

                <h3 style={{ marginTop: 50 }}>Consultor, escreva seu parecer</h3>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: '50vh', marginTop: 30, marginBottom: 40 }}>
                    <Radar
                        data={this.state.data}
                        options={{ maintainAspectRatio: true, scale: { ticks: { min: 1, max: 5 } } }}
                    />

                    <Line
                        data={this.state.data}
                        options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { min: 1, max: 5 } }] } }}
                    />
                    <Bar
                        data={this.state.data}
                        options={{ maintainAspectRatio: true, scales: { yAxes: [{ ticks: { min: 1, max: 5 } }] } }}
                    />
                </div>

                <TextareaAutosize
                    style={{ width: '100%', borderRadius: 5, borderColor: 'lightgrey', padding: 15, fontSize: '1rem', marginBottom: 10 }}
                    rowsMax={8}
                    rowsMin={8}
                    defaultValue={this.state.comment}
                    onBlur={(v) => { this.setState({ comment: v.target.value }) }}
                    placeholder={`Escreva seu parecer para ${this.state.name} da empresa ${this.state.company}...`}
                />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: 380, marginTop: 30, marginBottom: 40 }}>
                    <DefaultButton onClick={() => { this.saveComment() }} title={'Salvar Parecer'} />
                    <DefaultButton disabled={true} onClick={() => { }} title={`Enviar relatório`} color={'gray'} />
                </div>
            </div>
        )
    }
}