import React, { Component, forwardRef } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Firestore from '../api/firebase/Firestore';
import moment from 'moment';
import DeleteModal from '../components/DeleteModal';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';
import CurrencyHelper from '../helper/CurrencyHelper';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';

export default class PermissionPage extends Component {

    state = {
        docs: [],
        name: '',
        routes: {
            user: true,
            permission: true,
            params: true,
            maps_requested: true
        },
        loading: true,
        loadingModal: false,
        addModal: false,
    }

    async getDocs() {

        this.setState({ loading: true });

        let query = await Firestore.customQuery('permission_group').where('id_company', '==', SessionHelper.getData().id_company).get();
        let docs = [];

        query.forEach((doc, key) => {
            
            let routes = doc.data().routes;

            let data = { 
                name: doc.data().name,
                id: doc.id,
                user: routes['user'],
                permission: routes['permission'],
                params: routes['params'],
                maps_requested: routes['maps_requested'],
            };

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    componentDidMount() {

        this.getDocs();
    }

    async addPermission() {

        if (this.state.name && this.state.routes) {

            let data = {
                name: this.state.name,
                routes: this.state.routes,
                id_company: SessionHelper.getData().id_company,
            };

            try {

                this.setState({ loadingModal: true });

                await Firestore.insert(data, 'permission_group');

                toast.success("Cadastrado com sucesso", {
                    position: toast.POSITION.TOP_RIGHT
                });

                await this.setState({ loadingModal: false, addModal: false, name: ''});
                await this.getDocs();

            } catch (error) {
                toast.error("Houve um problema ao cadastrar", {
                    position: toast.POSITION.TOP_RIGHT
                });

                this.setState({ loadingModal: false });
            }
        } else {

            toast.warn("Preencha todos os campos", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    addModal() {
        return (
            <div>
                <DefaultInput label={'Nome'} onBlur={(v) => { this.setState({ name: v }) }}/>
                <div style={{paddingTop: 18}}>
                    <FormLabel component="legend">Menus</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.routes['user']} onChange={(v) => { let routes = this.state.routes; routes['user'] = v.target.checked; this.setState({ routes: routes }) }} />}
                            label="Usuários"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.routes['permission']} onChange={(v) => { let routes = this.state.routes; routes['permission'] = v.target.checked; this.setState({ routes: routes }) }} />}
                            label="Permissões"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.routes['params']} onChange={(v) => { let routes = this.state.routes; routes['params'] = v.target.checked; this.setState({ routes: routes }) }} />}
                            label="Ajustes"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.routes['maps_requested']} onChange={(v) => { let routes = this.state.routes; routes['maps_requested'] = v.target.checked; this.setState({ routes: routes }) }} />}
                            label="Mapas Solicitados"
                        />
                    </FormGroup>
                </div>

                <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                    <Button onClick={() => { this.addPermission() }} style={{fontWeight: 'bold', backgroundColor: Colors.primary, color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                    <Button onClick={() => { this.setState({ addModal: false }) }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultButton onClick={() => { this.setState({ addModal: true }) }} title={'Adicionar'}/>

                <DefaultTable
                title={'Grupo de Permissões'}
                width={window.screen.width - 280}
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Usuários', type: 'boolean', field: 'user' },
                    { title: 'Permissões', type: 'boolean', field: 'permission' },
                    { title: 'Ajustes', type: 'boolean', field: 'params' },
                    { title: 'Mapas Solicitados', type: 'boolean', field: 'maps_requested' },
                ]}
                data={this.state.docs}
                onRowUpdate={ async (oldData, newData) => {

                    let prev = this.state.docs;
                    prev[prev.findIndex(item => item.id === oldData.id)] = newData;

                    this.setState({ docs: prev });
                    this.forceUpdate();

                    if (oldData.id) {
                        
                        let data = {
                            name: newData.name,
                            routes: {
                                user: newData.user ? newData.user : false,
                                permission: newData.permission ? newData.permission : false,
                                params: newData.params ? newData.params : false,
                                maps_requested: newData.maps_requested ? newData.maps_requested : false,
                            }
                        };

                        await Firestore.update(data, 'permission_group', oldData.id);
                    }

                    toast.success("Editado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                onRowDelete={ async (oldData) => {

                    let prev = this.state.docs;
                    prev.splice(prev.indexOf(oldData), 1);
                    
                    this.setState({ docs: prev });

                    if (oldData.id) {
                        await Firestore.delete('permission_group', oldData.id);
                    }

                    toast.success("Removido com sucesso", {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    return prev;
                }}
                />

                <DefaultModal loading={this.state.loadingModal} content={this.addModal()} title={'Novo Grupo de Permissão'} onClose={() => { this.setState({ addModal: false }) }} open={this.state.addModal}/>
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
