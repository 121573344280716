import React, { Component, forwardRef } from 'react';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Colors from '../constants/Colors';

export default class components extends Component {
  render() {
    return (
        <MaterialTable
        isLoading={this.props.isLoading}
        style={{ marginTop: this.props.marginTop ? this.props.marginTop : 10, width: this.props.width ? this.props.width : '100%', height: this.props.height ? this.props.height : '', borderRadius: this.props.borderRadius ? this.props.borderRadius : ''}}
        options={{pageSize: this.props.pageSize ? this.props.pageSize : 10, minBodyHeight: this.props.height ? this.props.height : '72vh', maxBodyHeight: this.props.height ? this.props.height : '72vh', detailPanelType: 'single'}}
        detailPanel={this.props.detailPanel ? (data) => this.props.detailPanel(data) : null }
        onRowClick={(evt, rowData, togglePanel) => { this.props.onRowClick && this.props.onRowClick(evt, rowData, togglePanel) }}
        title={this.props.title}
        actions={this.props.actions}
        icons={{
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check style={{color: 'green'}} {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear style={{color: 'red'}} {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline style={{color: 'red'}} {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit style={{color: 'green'}} {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove  {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
        }}
        columns={this.props.columns}
        data={this.props.data}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsSelect: 'linhas',
                firstTooltip: 'Primeira Página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima Página',
                lastTooltip: 'Última Página'
            },
            toolbar: {
                nRowsSelected: '{0} registro(s) selecionados',
                searchPlaceholder: 'Pesquisar'
            },
            header: {
                actions: 'Ações'
            },
            body: {
                emptyDataSourceMessage: 'Nenhum registro encontrado',
                filterRow: {
                    filterTooltip: 'Filtrar'
                },
                editTooltip: 'Editar',
                deleteTooltip: 'Remover',
                editRow: {
                    cancelTooltip: 'Cancelar',
                    saveTooltip: 'Salvar',
                    deleteText: 'Tem certeza que deseja remover?'
                }
            },
            
        }}
        editable={{
            onRowUpdate: this.props.onRowUpdate ? (newData, oldData) =>
            new Promise((resolve) => {
                setTimeout(() => {
                resolve();
                if (oldData) {
                    this.props.onRowUpdate(oldData, newData);
                }
                }, 600);
            }) : null,
            onRowDelete: this.props.onRowDelete ? (oldData) =>
            new Promise((resolve) => {
                setTimeout(() => {
                resolve();
                
                this.props.onRowDelete(oldData);

                }, 600);
            }) : null,
        }}
        />
    )
  }
}
