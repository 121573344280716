import React, { Component } from 'react';
import { IconButton, Badge, Menu, MenuItem, Tooltip } from "@material-ui/core";
import Colors from '../constants/Colors';
import NotificationsIcon from "@material-ui/icons/Notifications";
import TodayIcon from "@material-ui/icons/Today";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import moment from 'moment';
import DefaultLoader from './DefaultLoader';
export default class NotificationCenter extends Component {

    state = {
        docs: [],
        limit: 0,
        interval: null,
        menuOpen: false,
        badgeCount: 0,
        loading: false,
    }

    componentDidMount() {

        let interval = setInterval(() => {

            this.getNotifications();

        }, 60000);

        this.setState({ interval: interval });
    }

    componentWillUnmount() {

        if (this.state.interval) {

            clearInterval(this.state.interval);

            this.setState({ interval: null });
        }
    }

    async toggleMenu() {

        let menu = !this.state.menuOpen;

        this.setState({ menuOpen: menu });

        if (menu) {

            await this.getNotifications();
            await this.markAsRead();
        }
    }

    async markAsRead() {

        this.setState({ badgeCount: 0 });

        this.state.docs.forEach((notification, key) => {
            if (!notification.viewed) { Firestore.update({ viewed: true }, 'notification', notification.id) }
        });
    }

    async getNotifications() {

        let docs = [];
        let limit = this.state.limit;
        let badgeCount = 0;

        limit += 5;

        // await this.setState({ loading: true });

        // let query = await Firestore.customQuery('notification')
        //     .where('id_company', '==', SessionHelper.getData().id_company)
        //     .orderBy('date', 'desc')
        //     .limit(limit)
        //     .get();

        // query.forEach((doc, key) => {

        //     let data = doc.data();
        //     data.id = doc.id;
        //     data.hover = false;

        //     if (!data.viewed) { badgeCount++ }

        //     docs.push(data);
        // });

        this.setState({ docs: docs, limit: limit, badgeCount: badgeCount, loading: false });
    }

    toggleHover(key, value) {

        let docs = this.state.docs;
        docs[key].hover = value;

        this.setState({ docs: docs });
    }

    async removeNotification(key) {

        let docs = this.state.docs;
        let doc = docs[key];

        docs.splice(key, 1);

        this.setState({ docs: docs });

        Firestore.delete('notification', doc.id);
    }

    renderNotifications() {

        if (this.state.docs.length) {

            return this.state.docs.map((notification, key) => {

                let color = notification.data.color ? notification.data.color : Colors.primary;
                let icon = notification.data.icon;

                return (
                    <MenuItem onMouseLeave={() => { this.toggleHover(key, false) }} onMouseEnter={() => { this.toggleHover(key, true) }} style={{ display: 'flex', flexDirection: 'row', borderBottom: this.state.docs[key + 1] ? '1px solid lightgrey' : '', paddingTop: 10, alignItems: 'flex-start' }}>

                        { icon === 'Today' && <TodayIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }} />}
                        { !icon && <NotificationsIcon style={{ border: `3px solid ${color}`, color: color, borderRadius: '100%', padding: 5, fontSize: 45, marginRight: 10 }} />}

                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ fontWeight: 'bold' }}>

                                {notification.title}

                                {this.state.docs[key].hover &&

                                    <Tooltip title={"Apagar"}>
                                        <ClearIcon style={{ float: 'right', color: 'grey', fontSize: 20 }} onClick={() => { this.removeNotification(key) }} />
                                    </Tooltip>

                                }
                            </div>
                            <div style={{ fontSize: 12 }}>{notification.body}</div>
                            <div style={{ fontSize: 10, alignSelf: 'flex-end', paddingTop: 10, color: 'grey' }}>{moment(notification.date.toDate()).format('DD/MM/YYYY HH:mm')}</div>
                        </div>

                    </MenuItem>
                )
            });

        } else {

            return <MenuItem style={{ padding: 100, fontSize: 12, color: 'grey' }}>{'Nenhuma Notificação'}</MenuItem>
        }
    }

    renderLoading() {
        return (
            <DefaultLoader size={25}
                color={'lightgrey'} />
        )
    }

    async clearNotifications() {

        let notifications = this.state.docs;

        this.setState({ docs: [], badgeCount: 0 });

        notifications.forEach((doc, key) => {

            Firestore.delete('notification', doc.id);
        });
    }

    renderHeader() {
        return (
            <MenuItem style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', fontSize: 12 }}>
                <div style={{ fontWeight: 'bold', color: 'black', fontSize: 13 }}>{'Notificações'}</div>
                <div>
                    <Tooltip title={"Limpar Tudo"}>
                        <DeleteIcon onClick={() => { this.clearNotifications() }} />
                    </Tooltip>
                </div>
            </MenuItem>
        )
    }

    renderSeeMore() {
        return (
            <MenuItem onClick={() => { if (!this.state.loading) { this.getNotifications() } }} style={{ display: 'flex', justifyContent: 'center', borderTop: '1px solid lightgrey', color: 'grey', fontSize: 12 }}>{this.state.loading ? this.renderLoading() : 'Carregar Mais'}</MenuItem>
        )
    }

    render() {

        return (
            <div style={{ display: 'flex' }}>
                <IconButton color="inherit" aria-controls={'primary-search-account-menu'} aria-haspopup="true" onClick={() => { this.toggleMenu() }}>
                    <Badge badgeContent={this.state.badgeCount} color="secondary">
                        <NotificationsIcon style={{ color: '#FFFFFF' }} />
                    </Badge>
                </IconButton>

                <Menu
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={'primary-search-account-menu'}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.menuOpen}
                    style={{ marginTop: 40, height: 500 }}
                    onClose={() => { this.toggleMenu() }} >

                    {this.renderHeader()}
                    {this.renderNotifications()}
                    {this.renderSeeMore()}

                </Menu>

            </div>
        )

    }
}
