import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import DefaultButton from '../components/DefaultButton';
import Colors from '../constants/Colors';
import { Card, Button, FormLabel, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, Input } from '@material-ui/core';
import Firestore from '../api/firebase/Firestore';
import DefaultModal from '../components/DefaultModal';
import DefaultInput from '../components/DefaultInput';
import { toast } from 'react-toastify';
import DefaultTable from '../components/DefaultTable';
import SessionHelper from '../helper/SessionHelper';
import BarChartIcon from '@material-ui/icons/BarChart';
import CheckIcon from '@material-ui/icons/Check';
import Functions from '../api/firebase/Functions';
import moment from 'moment';
import AdvisMapResult from '../components/AdvisMapResult';

export default class MapsRequestedPage extends Component {

    state = {
        loading: true,
        resultsModal: false,
        editId: null,
        answers: {}
    }

    async componentDidMount() {
        await this.getDocs();
    }

    async getDocs() {
        this.setState({ loading: true });

        let query = await Firestore.customQuery('contact').orderBy('date', 'desc').get();
        let docs = [];

        query.forEach((doc, key) => {

            let data = doc.data();
            data.id = doc.id;

            docs.push(data);
        });

        this.setState({ docs: docs, loading: false });
    }

    resultsModal() {
        if (this.state.resultsModal && this.state.answers) {
            return (
                <AdvisMapResult editId={this.state.editId} />
            );
        }
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <div style={styles.container}>
                <DefaultTable
                    title={'AdvisMaps Requisitados pelo site'}
                    actions={[
                        {
                            icon: BarChartIcon,
                            tooltip: 'Ver resultado do Questionário',
                            onClick: (event, rowData) => { this.setState({ editId: rowData.id, comment: rowData.comment || '', answers: rowData.answers, name: rowData.name, company: rowData.company }, () => { this.setState({ resultsModal: true }) }) }
                        },
                    ]}
                    columns={[
                        { title: 'Id', field: 'id', hidden: true },
                        { title: 'Nome', field: 'name', editable: false },
                        { title: 'Empresa', field: 'company', editable: false },
                        { title: 'Cargo', field: 'position', editable: false },
                        { title: 'Data', field: 'date', editable: false, render: rowData => <div>{moment(rowData.date.toDate ? rowData.date.toDate() : rowData.date).format('DD/MM/YYYY HH:mm:ss')}</div> },
                        { title: 'E-mail', field: 'email', editable: false },
                        { title: 'Telefone', field: 'phone', editable: false },
                        { title: 'Telefone', field: 'phone', editable: false },
                        // { title: 'Grupo de Permissão', field: 'id_permission_group', lookup: this.getPermissionLookup() },
                        // { title: 'Tipo', field: 'type', editable: false, render: rowData => <div>{rowData.type === 'admin' ? 'Administrador' : 'Atendente'}</div> },
                    ]}
                    data={this.state.docs}
                />
                <DefaultModal width={'95%'} loading={this.state.loadingModal} content={this.resultsModal()} title={`${this.state.name} de ${this.state.company}`} onClose={() => { this.setState({ resultsModal: false }, () => { this.getDocs() }) }} open={this.state.resultsModal} />
            </div>
        );
    }
}

const styles = {
    container: {
        padding: 25,
    }
}
