import React, { Component } from 'react';
import DefaultLoader from '../components/DefaultLoader';
import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import UnderConstruction from '../components/UnderConstruction';

export default class HomePage extends Component {

    state = {
        loading: false
    }

    render() {
        return this.state.loading ? <DefaultLoader /> : (
            <UnderConstruction/>
        );
    }
}
