import firebase from 'firebase/app'
import 'firebase/auth'

export default class Auth {

    static async verifyCurrentPassword(password) {
        
        let user = firebase.auth().currentUser;

        let credential = firebase.auth.EmailAuthProvider.credential(
            firebase.auth().currentUser.email,
            password
        );

        return user.reauthenticateWithCredential(credential).then(function() {
            return true;
        }).catch(function(error) { return false });
    }

    static async changePassword(newPassword) {

        if (newPassword !== '') {
            
            let user = firebase.auth().currentUser;

            return user.updatePassword(newPassword).then(() => {
                return true;
            }, (error) => {
                return error;
            });
        }
    }

    static async registerUser(email, password) {
        return await firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    static async registerUserApple(identityToken, nonce) {
        const provider = new firebase.auth.OAuthProvider("apple.com");
        const credential = provider.credential({
          idToken: identityToken,
          rawNonce: nonce
        });

        const firebaseUserCredential = await firebase.auth().signInWithCredential(credential);

        return firebaseUserCredential.user;
    }
    
    static async registerUserFacebook(token) {
        const credential = firebase.auth.FacebookAuthProvider.credential(token);
        const firebaseUserCredential = await firebase.auth().signInWithCredential(credential);

        return firebaseUserCredential.user;
    }

    static async registerUserGoogle(token) {
        const credential = firebase.auth.GoogleAuthProvider.credential(null, token);
        const firebaseUserCredential = await firebase.auth().signInWithCredential(credential);

        return firebaseUserCredential.user;
    }

    static async login(email, password) {

        try {

            return await firebase.auth().signInWithEmailAndPassword(email, password);

        } catch (error) {

            return error;
        }
    }

    static async resetPassword(email) {
        return await firebase.auth().sendPasswordResetEmail(email);
    }

    static async verifyEmail(email) {
        return await firebase.auth().sendSignInLinkToEmail(email);
    }
}