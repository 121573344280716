import React, { Component } from 'react';
import Colors from '../constants/Colors';
import DefaultLoader from './DefaultLoader';

export default class UnderConstruction extends Component {

    state = {
        loading: false,
    }

    async componentDidMount() {
        await this.setState({ loading: true });


        await this.setState({ loading: false });
    }

    render() {
        return (this.state.loading ? <DefaultLoader /> :
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', textAlign: 'center', flex: 1, width: '100%', height: '70vh' }}>
                    <img style={{ height: window.screen.height < 900 ? '40%' : '65%', marginLeft: 'auto', marginRight: 'auto', marginBottom: 8 }} src={process.env.PUBLIC_URL + '/under-construction.png'} />
                    
                    <div style={{ textAlign: 'center', fontSize: 26, color: Colors.primary, marginTop: 35, fontWeight: '800' }}>{this.props.title || `Módulo em construção`}</div>

                </div>
            </div>
        )
    }
}
