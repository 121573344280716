import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';
import Colors from '../constants/Colors';

export default class DeleteModal extends Component {

    render() {
        return (
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{ display: 'flex',alignItems: 'center', justifyContent: 'center' }}
            open={this.props.open}
            onClose={() => { this.props.onClose() }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }} >
                <Fade in={this.props.open}>
                <div style={{backgroundColor: '#fff', borderRadius: 5, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                    <h2 id="transition-modal-title">Tem certeza que deseja deletar?</h2>
                    <div style={{alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50}}>
                        <Button onClick={() => { this.props.onConfirm() }} style={{fontWeight: 'bold', backgroundColor: 'red', color: '#fff', width: '48%', marginRight: '2%'}} variant={'contained'}>{'CONFIRMAR'}</Button>
                        <Button onClick={() => { this.props.onRefuse() }} style={{width: '48%', fontWeight: 'bold', marginLeft: '2%'}} variant={'contained'}>{'CANCELAR'}</Button>
                    </div>
                </div>
                </Fade>
            </Modal>
        )
    }
}
