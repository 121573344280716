export default class SessionHelper {
    
    constructor() {
        this.firebaseAuth = null;
        this.data = null;
    }

    static setFirebaseAuth(auth) {
        this.firebaseAuth = auth;
    }

    static getFirebaseAuth() {
        return this.firebaseAuth;
    }

    static setData(data) {
        this.data = data;
    }

    static getData() {
        return this.data;
    }

    static destroy() {
        this.firebaseAuth = null;
        this.data = null;

        return true;
    }
}